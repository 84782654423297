import React from 'react';
import styled from 'styled-components';

import { Text, Seo } from '../components';
import About from '../components/Layout/About';
import bia from '../images/foto.jpg';

const Content = styled.div`
  width: 100%;
  max-width: 560px;
  margin-bottom: 150px;
  text-align: left;

  @media (max-width: 1175px) {
    max-width: 100%;
    margin-top: 30px;
  }
`;

const Image = styled.img`
  position: absolute;
  right: 20px;
  top: 170px;
  max-width: 100%;

  @media (max-width: 1175px) {
    display: none;
  }
`;

const IndexPage = () => (
  <About
    title={
      <>
        Foi meu interesse pelas <strong>pessoas</strong> que me trouxe até aqui.
        E foram suas histórias que me motivaram a continuar!
      </>
    }
    image={<Image src={bia} alt="Beatriz Figueiredo" />}
  >
    <Seo title="Sobre mim" />
    <Content>
      <div>
        <Text size={16} color="#9B9B9B" lineHeight="27px" marginBottom={27}>
          Ainda sem saber qual curso de faculdade faria, eu só tinha uma
          certeza: eu queria lidar com as pessoas, conhecer suas histórias e
          poder trazer algum impacto positivo para elas. Até soa clichê, mas o
          que mais me facina no mundo da experiência é poder ter esse contato
          próximo e tangibilizar soluções que vão facilitar vidas.
        </Text>
        <Text size={16} color="#9B9B9B" lineHeight="27px">
          This portfolio is in Portuguese because I am not fluent in English
          yet, but I spend a pretty time studying for this goal. I went to
          Canada in 2018 for 3 months to improve my English. After that, I
          discovered an amazing world meeting new cultures and new people.
        </Text>
      </div>
    </Content>
  </About>
);

export default IndexPage;
