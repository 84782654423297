import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Header, GlobalStyle, Footer, Container, Divider, Title } from '..';

const Wrapper = styled.div`
  text-align: center;

  ${Title} {
    max-width: 560px;
    width: 100%;
    padding-bottom: 40px;
    text-align: left;

    @media (max-width: 1175px) {
      max-width: 100%;
    }
  }

  @media (max-width: 1175px) {
    img {
      margin-bottom: 40px;
    }
  }
`;

const About = ({ children, title, image }) => (
  <Wrapper>
    <GlobalStyle />
    <Container fluid backgroundColor="#F6F6F6">
      <Container>
        <Header />
        <Title as="h2" size={34} weight={500} marginBottom={40} marginTop={20}>
          {title}
        </Title>
        {image}
      </Container>
    </Container>
    <Container>
      <main>{children}</main>
    </Container>
    <Divider />
    <Container>
      <Footer />
    </Container>
  </Wrapper>
);

About.propTypes = {
  children: PropTypes.node.isRequired,
};

export default About;
